interface RouteComponent {
  path: string;
}

interface RoutesConfig {
  general: {
    linkExpired: RouteComponent;
    somethingWentWrong: RouteComponent;
    transactionCancelled: RouteComponent;
  };
  mobileMatch: {
    basePath: string;
    verify: RouteComponent;
    failed: RouteComponent;
  };
  mobileMatchOTP: {
    basePath: string;
    intro: RouteComponent;
    verify: RouteComponent;
    failed: RouteComponent;
  };
  bioGovId: {
    basePath: string;
    intro: RouteComponent;
    licenseSelection: RouteComponent;
    licenseFrontAccepted: RouteComponent;
    licenseFrontRejected: RouteComponent;
    licenseBackAccepted: RouteComponent;
    licenseBackRejected: RouteComponent;
    licenseBackCapture: RouteComponent;
    passportSelection: RouteComponent;
    passportAccepted: RouteComponent;
    passportRejected: RouteComponent;
    success: RouteComponent;
    failed: RouteComponent;
  };
}

const routesConfig: RoutesConfig = {
  general: {
    linkExpired: {
      path: "/link-expired"
    },
    somethingWentWrong: {
      path: "/something-went-wrong"
    },
    transactionCancelled: {
      path: "/transaction-cancelled"
    }
  },
  mobileMatch: {
    basePath: "/mobile-match",
    verify: {
      path: "/mobile-match/verify"
    },
    failed: {
      path: "/mobile-match/failed"
    }
  },
  mobileMatchOTP: {
    basePath: "/mobile-match-otp",
    intro: {
      path: "/mobile-match-otp/intro"
    },
    verify: {
      path: "/mobile-match-otp/verify"
    },
    failed: {
      path: "/mobile-match-otp/failed"
    }
  },
  bioGovId: {
    basePath: "/bio-gov-id",
    intro: {
      path: "/bio-gov-id/intro"
    },
    licenseSelection: {
      path: "/bio-gov-id/license"
    },
    licenseFrontAccepted: {
      path: "/bio-gov-id/license/front/accepted"
    },
    licenseFrontRejected: {
      path: "/bio-gov-id/license/front/rejected"
    },
    licenseBackCapture: {
      path: "/bio-gov-id/license/back"
    },
    licenseBackAccepted: {
      path: "/bio-gov-id/license/back/accepted"
    },
    licenseBackRejected: {
      path: "/bio-gov-id/license/back/rejected"
    },
    passportSelection: {
      path: "/bio-gov-id/passport"
    },
    passportAccepted: {
      path: "/bio-gov-id/passport/accepted"
    },
    passportRejected: {
      path: "/bio-gov-id/passport/rejected"
    },
    success: {
      path: "/bio-gov-id/success"
    },
    failed: {
      path: "/bio-gov-id/failed"
    }
  }
};

export default routesConfig;
