import React, { lazy, Suspense } from "react";
import "./style/App.css";
import {
  createHashRouter,
  Route,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import routesConfig from "utils/routesConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
const MobileMatchVerify = lazy(() => import("./pages/MobileMatch/Verify"));
const MobileMatchOTP = lazy(() => import("./pages/MobileMatchOTP/Intro"));
const BioGovIDIntro = lazy(() => import("./pages/BioGovID/Intro"));
const BioGovIdLicenseSelection = lazy(
  () => import("pages/BioGovID/LicenseSelection")
);
const BioGovIdPassportSelection = lazy(
  () => import("pages/BioGovID/PassportSelection")
);
const BioGovIdLicenseFrontAccepted = lazy(
  () => import("pages/BioGovID/LicenseFrontAccepted")
);
const BioGovIdLicenseFrontRejected = lazy(
  () => import("pages/BioGovID/LicenseFrontRejected")
);
const BioGovIdLicenseBackCapture = lazy(
  () => import("pages/BioGovID/LicenseBackCapture")
);
const BioGovIdLicenseBackAccepted = lazy(
  () => import("pages/BioGovID/LicenseBackAccepted")
);
const BioGovIdLicensePassportAccepted = lazy(
  () => import("pages/BioGovID/PassportAccepted")
);
const BioGovIdPassportRejected = lazy(
  () => import("pages/BioGovID/PassportRejected")
);
const BioGovIdLicenseBackRejected = lazy(
  () => import("pages/BioGovID/LicenseBackRejected")
);
const MobileMatchOTPVerify = lazy(() => import("pages/MobileMatchOTP/Verify"));
const BioGovIdFailedVerification = lazy(
  () => import("pages/BioGovID/FailedVerification")
);
const IDProofingSuccess = lazy(() => import("pages/BioGovID/Success"));
const MobileMatchFailedVerification = lazy(
  () => import("pages/MobileMatch/FailedVerification")
);
const MobileMatchOTPFailedVerification = lazy(
  () => import("pages/MobileMatchOTP/FailedVerification")
);
const LinkExpired = lazy(() => import("pages/General/LinkExpired"));
const SomethingWentWrong = lazy(
  () => import("pages/General/SomethingWentWrong")
);
const NotFound = lazy(() => import("pages/General/NotFound"));
const TransactionCancelled = lazy(
  () => import("pages/General/TransactionCancelled")
);

function App() {
  const queryClient = new QueryClient();

  const router = createHashRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path={routesConfig.general.linkExpired.path}
          element={<LinkExpired />}
        />
        <Route
          path={routesConfig.general.somethingWentWrong.path}
          element={<SomethingWentWrong />}
        />
        <Route
          path={routesConfig.general.transactionCancelled.path}
          element={<TransactionCancelled />}
        />
        <Route
          path={routesConfig.mobileMatch.verify.path}
          element={<MobileMatchVerify />}
        />
        <Route
          path={routesConfig.mobileMatch.failed.path}
          element={<MobileMatchFailedVerification />}
        />
        <Route
          index
          path={routesConfig.mobileMatchOTP.intro.path}
          element={<MobileMatchOTP />}
        />
        <Route
          index
          path={routesConfig.mobileMatchOTP.verify.path}
          element={<MobileMatchOTPVerify />}
        />
        <Route
          path={routesConfig.mobileMatchOTP.failed.path}
          element={<MobileMatchOTPFailedVerification />}
        />
        <Route
          index
          path={routesConfig.bioGovId.intro.path}
          element={<BioGovIDIntro />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseSelection.path}
          element={<BioGovIdLicenseSelection />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseFrontAccepted.path}
          element={<BioGovIdLicenseFrontAccepted />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseFrontRejected.path}
          element={<BioGovIdLicenseFrontRejected />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseBackCapture.path}
          element={<BioGovIdLicenseBackCapture />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseBackAccepted.path}
          element={<BioGovIdLicenseBackAccepted />}
        />
        <Route
          index
          path={routesConfig.bioGovId.licenseBackRejected.path}
          element={<BioGovIdLicenseBackRejected />}
        />
        <Route
          index
          path={routesConfig.bioGovId.passportSelection.path}
          element={<BioGovIdPassportSelection />}
        />
        <Route
          index
          path={routesConfig.bioGovId.passportAccepted.path}
          element={<BioGovIdLicensePassportAccepted />}
        />
        <Route
          index
          path={routesConfig.bioGovId.passportRejected.path}
          element={<BioGovIdPassportRejected />}
        />
        <Route
          index
          path={routesConfig.bioGovId.success.path}
          element={<IDProofingSuccess />}
        />
        <Route
          index
          path={routesConfig.bioGovId.failed.path}
          element={<BioGovIdFailedVerification />}
        />
        <Route index path="*" element={<NotFound />} />
      </Route>
    )
  );

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)"
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<></>}>
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
      <ToastContainer
        autoClose={3000}
        position={isDesktop ? "bottom-left" : "bottom-center"}
        theme="colored"
        pauseOnFocusLoss={true}
        stacked={true}
      />
    </>
  );
}

export default App;
